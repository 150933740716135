<script>
import { mapGetters } from 'vuex';
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import draggable from 'vuedraggable'
import { popModal, pushModal } from "@/modals";
import { uuid } from 'vue-uuid';
// import { SimpleBar } from 'simplebar-vue3';
import { gmapMarkers } from '@/helpers'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    draggable,
    //SimpleBar
  },
  props: {
    fields: {
      type: Array
    },
    field:{
       type: Object,
       required: true
    }
  },
  data() {
    return {
      mapMarkerPathList: gmapMarkers(),
      title: null,
      errors: [],
      dd:{
        value: '',
        text: ''
      },
      item: {
        code: '',
        name: '',
        type: 'String',
        required: false,
        isUnique: false,
        precision: 0,
        dropdownItems: [],
        directory: null,
        directoryLookupControl: 'Dropdown',
        directoryRelatedFieldId: null,
        directoryFieldId: null,
        rootDirectoryFieldId: null
      }
    };
  },
  validations() {
    const rules = {
      item: {
        name: { required }
      }
    };

     if(this.item.type === 'Directory') {
      rules.item.directoryId = { required };
    }

    return rules;
  },
  created(){
    this.title = this.$t('customFields.tableColumn') ;
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
     ...mapGetters('directories', {
      directories: 'items',
      allCustomFields: 'allCustomFields'
    }),
    ...mapGetters('customFields', {
      processing: 'processing'
    })
  },
  methods: {
    async close() {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close");
    },
    async init() {
      this.item.id = this.field.id || uuid.v1();
      this.item.type = this.field.type || 'String';
      this.item.name = this.field.name;
      this.item.showOnDirectoryForm = this.field.showOnDirectoryForm || false;
      this.item.nameOnDirectoryForm = this.field.nameOnDirectoryForm;
      this.item.displayDirectoryFieldId = this.field.displayDirectoryFieldId || null;
      this.item.rootDirectoryFieldId = this.field.rootDirectoryFieldId || null;
      this.item.code = this.field.code;
      this.item.required = this.field.required || false;
      this.item.hidden = this.field.hidden || false;
      this.item.isUnique = this.field.isUnique || false;
      this.item.description = this.field.description;
      this.item.precision = this.field.precision || 0;
      this.item.dropdownItems = this.field.dropdownItems || [];
      this.item.directoryId = this.field.directoryId || null;
      this.item.directoryLookupControl =  this.field.directoryLookupControl || 'Dropdown';
      this.item.directoryRelatedFieldId = this.field.directoryRelatedFieldId || null;
      this.item.directoryFieldId = this.field.directoryFieldId || null;
      this.item.showOnMap = this.field.showOnMap;
      this.item.mapMarkerColor = this.field.mapMarkerColor;
      this.item.mapMarkerScale = this.field.mapMarkerScale || 1;
    },
   
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        const definition = {...this.item };
        this.$emit('save', definition);
        this.close();
      }
    },
    deleteDropdownItem(i){
      this.item.dropdownItems = [...this.item.dropdownItems.filter(x => x !== i) ];
    },
    addDropdownItem(){
      let text = this.dd.text;
      let value = uuid.v1();

      if(text && !value) {
        value = text;
      }

      if(!text && value) {
        text = value;
      }

      if(text && value) {
        this.item.dropdownItems = [...this.item.dropdownItems, {value, text} ];
        this.dd = {
          value: null,
          text: null
        };
      }
    },
    getRelatedFields() {
      const relField = this.fields.find(x => (x.id === this.item.directoryFieldId));
      
      if(relField) {
        const dir = this.directories.find(x => x.id === relField.directoryId);
        if(dir) {
          return dir.fields;
        }
      }
      return [];
    },
    getDirectoryName(dirId) {
      const directory = this.directories.find(x => x.id === dirId);
      return (directory || {}).name || null;
    },
    getDirName(dirId) {
      const dir = this.directories.find(x => x.id === dirId);
      if(dir) {
        return dir.name;
      }
      return [];
    },
    getDirFields(dirId) {
      const dir = this.directories.find(x => x.id === dirId);
      if(dir) {
        return dir.fields;
      }
      return [];
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
            {{title}}
             <small class="small text-muted font-size-12 d-block" v-if="item.id">
                ID: {{item.id}}
              </small>
          </h5>
        
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

            <div class="mb-4">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.name") }}</label>
              <div>
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter Name" 
                  id="name-input" 
                  v-model="item.name"
                  :class="{ 'is-invalid': v$.item.name.$error }"
                />
                <div class="invalid-feedback">
                 {{ $t("common.pleaseEnter") }} {{ $t("customFields.fields.name") }}
                </div> 
              </div>
            </div>
            <div class="mb-4">
              <label for="code-input" class="col-form-label">{{ $t("customFields.fields.code") }}</label>
              <div>
                <input 
                  type="text" 
                  class="form-control" 
                  id="code-input" 
                  v-model="item.code"
                />
              </div>
            </div>
            <div class="mb-4">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.type") }}</label>
              <div>
                <select class="form-control" v-model="item.type">
                  <option value="String">{{ $t("customFieldTypes.String") }}</option>
                  <option value="Date">{{ $t("customFieldTypes.Date") }}</option>
                  <option value="Dropdown">{{ $t("customFieldTypes.Dropdown") }}</option>
                  <option value="Number">{{ $t("customFieldTypes.Number") }}</option>
                  <option value="Link">{{ $t("customFieldTypes.Link") }}</option>
                  <option value="Address">{{ $t("customFieldTypes.Address") }}</option>
                  <option value="PhoneNumber">{{ $t("customFieldTypes.PhoneNumber") }}</option>
                  <option value="Directory">{{ $t("customFieldTypes.Directory") }}</option>
                  <option value="DirectoryRelatedField">{{ $t("customFieldTypes.DirectoryRelatedField") }}</option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Number'">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.precision") }}</label>
              <div>
                <input class="form-control" type="number" v-model.number="item.precision"/>
              </div>
            </div>
            <div v-if="item.type === 'Address'" class="row mb-3">
              <div class="col-md-auto">
                <div class="form-check form-switch form-switch-md">
                  <input class="form-check-input" type="checkbox" v-model="item.showOnMap"/>
                  <label class="form-check-label">{{ $t("customFields.fields.showOnMap") }}</label>
                </div>
              </div>
              <!-- <div class="col-md-auto" v-if="item.showOnMap">
                <div class="dropdown d-inline-block">
                  <a
                    class=""
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                    <img style="width:20px; height:34px" :src="(mapMarkerPathList.find(x => x.value === (item.markerIconPath || null)) || {}).path"/>
                    
                    <i class="mdi mdi-chevron-down"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-notifications-dropdown-v"
                  >
                    <SimpleBar data-simplebar style="max-height: 200px">
                      <a class="m-2 d-inline-block" href="javascript:void(0)" v-for="m in mapMarkerPathList" :key="m.path" @click="item.markerIconPath = m.value">
                        <img style="width:20px; height:34px" :src="m.path"/>
                      </a>
                    </SimpleBar>
                  </div>
                </div>
                            
              </div> -->
            </div>
            <div v-if="item.type === 'Address' && item.showOnMap" class="row mb-3">
                <div class="col-md-7">
                  <label for="code-input" class="col-form-label">{{ $t("customFields.fields.mapMarkerColor") }}</label>
                  <div>
                    <div class="input-group">
                      <input type="color" class="form-control" v-model="item.mapMarkerColor" style="height:38px"/>
                      <input type="text" class="form-control" v-model="item.mapMarkerColor"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <label for="code-input" class="col-form-label">{{ $t("customFields.fields.mapMarkerScale") }}</label>
                  <div>
                     <input type="text" class="form-control" v-model.number="item.mapMarkerScale"/>
                  </div>
                </div>
                
            </div>
            <div class="mb-4" v-if="item.type === 'Directory'">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.directory") }}</label>
              <div>
                <select 
                  class="form-control" 
                  v-model="item.directoryId"  
                  :class="{ 'is-invalid': (v$.item.directoryId || {}).$error }"
                >
                  <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                  <option v-for="d in directories" :key="d.id" :value="d.id">
                    {{d.name}}
                  </option>
                </select>
                <div class="invalid-feedback">
                 {{ $t("common.pleaseEnter") }} {{ $t("customFields.fields.directory") }}
                </div>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Directory' && item.directoryId">
              <label for="name-input" class="col-form-label">{{ $t("common.displayField") }}</label>
              <div>
                <select class="form-control" v-model="item.displayDirectoryFieldId">
                  <option :value="null">{{ $t("common.displayFieldIsDisplay") }} </option>
                  <option value="Number">{{$t('directory.fields.number')}}</option>
                  <option v-for="(f) in getDirFields(item.directoryId).filter(x => x.type === 'String' || x.type === 'Text' || x.type === 'Number' || x.type === 'Address'  )" :key="f.id" :value="f.id">{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Directory' && item.directoryId">
              <label for="name-input" class="col-form-label">{{ $t("common.hierarchy") }} [{{ $t("common.rootField") }}]</label>
              <div>
                <select class="form-control" v-model="item.rootDirectoryFieldId">
                  <option :value="null">{{ $t("common.none") }} </option>
                  
                  <option v-for="(f) in getDirFields(item.directoryId).filter(x => x.type === 'Directory')" :key="f.id" :value="f.id">{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Directory' && getDirectoryName(item.directoryId)">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" v-model="item.showOnDirectoryForm"/>
                <label class="form-check-label">{{ $t("customFields.fields.showOnForm") }} [{{getDirectoryName(item.directoryId)}}]</label>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Directory' && item.showOnDirectoryForm && getDirectoryName(item.directoryId)">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.nameOnForm") }}  [{{getDirectoryName(item.directoryId)}}]</label>
              <div>
                <input type="text" class="form-control" v-model="item.nameOnDirectoryForm"/>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'DirectoryRelatedField'">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.selectDirectoryFieldLabel") }}</label>
              <div>
                <select class="form-control" v-model="item.directoryFieldId">
                   <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                  <option v-for="(f) in fields.filter(x => x.type === 'Directory')" :key="f.id" :value="f.id">{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'DirectoryRelatedField' && item.directoryFieldId">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.selectDirectoryRelatedFieldLabel") }}</label>
              <div>
                <select class="form-control" v-model="item.directoryRelatedFieldId">
                   <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                  <option v-for="f in getRelatedFields()" :key="f.id" :value="f.id">{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Directory'">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.directoryLookupControl") }}</label>
              <div>
                <select class="form-control" v-model="item.directoryLookupControl">
                  <option value="Dropdown">
                   {{ $t("customFields.fields.lookupControlDropdown") }}
                  </option>
                  <option value="Typeahead">
                   {{ $t("customFields.fields.lookupControlTypeahead") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mb-4" v-if="item.type === 'Dropdown'">
              <label for="name-input" class="col-form-label">{{ $t("customFields.fields.dropdownItems") }}</label>
            
              <div>
                <table class="table table-sm table-borderless mb-0">
                  <thead>
                    <tr>
                      <th style="max-width:46px; width:46px">
                        <a href="javascript:void(0)" @click="addDropdownItem" class="btn-light btn ">
                          <eva-icon class="icon nav-icon text-primar" name="plus-outline"></eva-icon>
                        </a>
                      </th>
                     
                       <th  class="col ">
                        <input class="form-control" type="text" v-model="dd.text" placeholder="Text" v-on:keyup.enter="addDropdownItem"/>
                      </th>
                       <th style="max-width:30px; width:30px">
                        
                      </th>
                    </tr>
                  </thead>
                  <draggable
                    tag="tbody"
                    v-model="item.dropdownItems" 
                    group="dditems"
                    @start="drag=true" 
                    @end="drag=false" 
                    item-key="index"
                  >
                    <template #item="{element}">
                      <tr>
                        <td style="max-width:50px; width:50px"  class="text-center">
                          <a href="javascript:void(0)" >
                            <i class="mdi mdi-drag font-size-22 mt-1"></i>
                          </a>
                        </td>
                       
                        <td>
                          <input class="form-control" type="text" v-model="element.text" placeholder="Text"/>
                        </td> 
                         <td>
                          <a href="javascript:void(0)" class="btn-light btn" @click="deleteDropdownItem(element)" >
                            <i class="bx bx-trash-alt  text-danger"></i>
                          </a>
                        </td> 
                      </tr>
                    </template>  
                  </draggable>
                </table>
                
              </div>
            </div>
            <div class="row" v-if="item.type !== 'DirectoryRelatedField'">
              <div class="col-md-12">
                <div class=" mb-4">
                  <div class="">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input class="form-check-input" type="checkbox" id="isadmin-input" v-model="item.required"/>
                      <label class="form-check-label" for="isadmin-input">{{ $t("customFields.fields.required") }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-if="item.type !== 'Directory' && item.type !== 'Table'">
                <div class=" mb-4">
                  <div class="">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input class="form-check-input" type="checkbox" id="isadmin-isUnique" v-model="item.isUnique"/>
                      <label class="form-check-label" for="isadmin-isUnique">{{ $t("customFields.fields.unique") }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class=" mb-4">
                  <div class="">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input class="form-check-input" type="checkbox" id="isadmin-hidden" v-model="item.hidden"/>
                      <label class="form-check-label" for="isadmin-hidden">{{ $t("customFields.fields.hidden") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer row">
          <div class="col">
            <button
              type="button"
              class="btn btn-light"
              @click="close"
            >
              {{ $t("common.closeButtonTitle") }}
            </button>
          </div>
          <div class="col text-end">
            <button type="button" class="btn btn-primary"
              @click="submit"
            >
            <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
            <i class="fa fa-save me-2" v-if="!processing"/>
              {{ $t("common.saveButtonTitle") }}
            </button>
          </div>
          
        
        </div>
      </div>
    </div>
  </div>
</template>
