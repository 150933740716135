<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { parseErrors} from "@/helpers"
import { popModal, pushModal } from "@/modals";
import { uuid } from 'vue-uuid';
import draggable from 'vuedraggable'
import TableFieldEditForm from './table-field-edit-form.vue'
import { customFieldTypeName, gmapMarkers } from '@/helpers'
// import { SimpleBar } from 'simplebar-vue3';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    draggable,
    TableFieldEditForm,
    //SimpleBar
  },
  props: {
    table: {
      type: Boolean,
    },
    directoryType:{
       type: String,
       required: false
    },
    field:{
       type: Object,
       required: true
    },
    fields: {
       type: Array,
    }
  },
  data() {
    return {
      mapMarkerPathList: gmapMarkers(),

      tableField: null,
      id: null,
      errors: [],
      tag:'',
      dd:{
        value: '',
        text: ''
      },
      deletedAt: null,
      item: {
        code: '',
        name: '',
        cssClass: null,
        bgColor: null,
        bgColorEnabled: null,
        fgColor: null,
        fgColorEnabled: null,
        type: 'String',
        required: false,
        isUnique: false,
        visibleOnGrid: true,
        description: '',
        rows: 5,
        precision: 0,
        dropdownItems: [],
        directory: null,
        tableInlineEdit: false,
        directoryLookupControl: 'Dropdown',
        tableFields: [],
        suggestionEnabled: false
      }
    };
  },
  validations() {
    const rules = {
      item: {
        name: { required }
      }
    };

     if(this.item.type === 'Directory') {
      rules.item.directoryId = { required };
    }

    return rules;
  },
  created(){ 
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    title() {
      return this.isNew ? this.$t('customFields.newField') : this.$t('customFields.editField')
    },
    isNew () {
      return this.id ? false : true;
    },
     ...mapGetters('directories', {
      directories: 'items',
      allCustomFields: 'allCustomFields'
    }),
    ...mapGetters('customFields', {
      processing: 'processing'
    })
  },
  methods: {
    ...mapActions('customFields', {
      create: 'create',
      update: 'update',
    }),
    
    getTypeName(type){
      return this.$t('customFieldTypes.' + customFieldTypeName[type]);
    },
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    async init() {
    
      this.deletedAt = this.field.deletedAt;
      this.item.type = this.field.definition.type || 'String';
      this.item.name = this.field.definition.name;
      this.item.code = this.field.definition.code;
      this.item.cssClass = this.field.definition.cssClass || null;
      this.item.bgColor = this.field.definition.bgColor || null;
      this.item.bgColorEnabled = this.field.definition.bgColorEnabled || false;
      this.item.fgColor = this.field.definition.fgColor || null;
      this.item.fgColorEnabled = this.field.definition.fgColorEnabled || false;
      this.item.inlineLabel = this.field.definition.inlineLabel;
      this.item.displayDirectoryFieldId = this.field.definition.displayDirectoryFieldId || null;
      this.item.showOnDirectoryForm = this.field.definition.showOnDirectoryForm || false;
      this.item.nameOnDirectoryForm = this.field.definition.nameOnDirectoryForm;
      this.item.required = this.field.definition.required || false;
      this.item.hidden = this.field.definition.hidden || false;
      this.item.isUnique = this.field.definition.isUnique || false;
      this.item.showOnMap = this.field.definition.showOnMap || false;
      this.item.mapMarkerColor = this.field.definition.mapMarkerColor;
      this.item.mapMarkerScale = this.field.definition.mapMarkerScale || 1;
      this.item.directoryFieldId = this.field.definition.directoryFieldId || null;
      this.item.directoryRelatedFieldId = this.field.definition.directoryRelatedFieldId || null;
      this.item.suggestionEnabled = this.field.definition.suggestionEnabled || false;

      this.item.visibleOnGrid = this.field.definition.visibleOnGrid;
      if(this.item.visibleOnGrid !== false && this.item.visibleOnGrid !== true) {
        this.item.visibleOnGrid = false;
      }
      
      this.item.visibleOnBoard = this.field.definition.visibleOnBoard;
      if(this.item.visibleOnBoard !== false && this.item.visibleOnBoard !== true) {
        this.item.visibleOnBoard = false;
      }

      this.item.description = this.field.definition.description;
      this.item.rows = this.field.definition.rows || 5;
      this.item.precision = this.field.definition.precision || 0;
      this.item.dropdownItems = this.field.definition.dropdownItems || [];
      this.item.directoryId = this.field.definition.directoryId || null;
      this.item.tableFields = this.field.definition.tableFields || [];
      this.item.tableInlineEdit = this.field.definition.tableInlineEdit || false;
      this.item.directoryLookupControl =  this.field.definition.directoryLookupControl || 'Dropdown';

      this.id = this.field.id || (this.field.definition || {}).id || null;
    },
   
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        const definition = {...this.item };
        const { entity } = this;
        if(entity) {
          let req = this.isNew ? this.create({entity, dto: { definition  }}) : this.update({ entity, id: this.id, dto: { definition }});
          req.then(() => {
            this.$toast.open({message: 'Data was saved successfully.'});
            this.close(true);
          })
          .catch(error => {
            this.errors = parseErrors(error);
          });
        } else{
          this.close({...definition, id: this.id});
        }
      }
    },
    deleteDropdownItem(i){
      this.item.dropdownItems = [...this.item.dropdownItems.filter(x => x !== i) ];
    },
    addDropdownItem(){
      let text = this.dd.text;
      let value = uuid.v1();

      if(text && !value) {
        value = text;
      }

      if(!text && value) {
        text = value;
      }

      if(text && value) {
        this.item.dropdownItems = [...this.item.dropdownItems, {value, text} ];
        this.dd = {
          value: null,
          text: null
        };
      }
    },
    onTableFieldSave(tf){
      const existed = this.item.tableFields.find(x => x.id === tf.id);
      if(!existed) {
        this.item.tableFields = [...this.item.tableFields, tf];
      }
      else {
        var index = this.item.tableFields.indexOf(existed);
        if(index >= 0) {
          const o = [...this.item.tableFields];
          o[index] = tf;
          this.item.tableFields = o;
        }
      }
    },
    getDirectoryName(dirId) {
      const directory = this.directories.find(x => x.id === dirId);
      return (directory || {}).name || null;
    },
    getRelatedFields() {
      const relField = (this.fields || []).find(x => (x.id === this.item.directoryFieldId));
      if(relField) {
        const dir = this.directories.find(x => x.id === relField.directoryId);
        if(dir) {
          return dir.fields;
        }
      }
      return [];
    },
    getDirName(dirId) {
      const dir = this.directories.find(x => x.id === dirId);
      if(dir) {
        return dir.name;
      }
      return [];
    },
    getDirFields(dirId) {
      const dir = this.directories.find(x => x.id === dirId);
      if(dir) {
        return dir.fields;
      }
      return [];
    }
  }
};
</script>

<template>
  <div>
    <table-field-edit-form v-if="tableField" :field="tableField" @close="tableField = null" @save="(tf) => onTableFieldSave(tf)" :fields="item.tableFields">

    </table-field-edit-form>

    <div
      class="modal"
      ref="modal"
    >
      <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document" :class="{'modal-xl': item.type === 'Table'}">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-4">
              {{title}}
              <span class="badge bg-danger ms-2" v-if="deletedAt">{{ $t("customFields.deleted") }}</span>
              <small class="small text-muted font-size-12 d-block" v-if="!isNew">
                ID: {{id}}
              </small>
            </h5>
          
            <button
              type="button"
              class="btn-close"
              @click="close()"
              aria-label="Close"
            ></button>

           
          </div>
          <div class="modal-body">
         
            <form>
              <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
                <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
                <ul class="list-unstyled">
                  <li v-for="e in errors" :key="e">{{e}}</li>
                </ul>
              </div>

              <div class="row gy-2 gx-3 align-items-center">
                 <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="name-input" class="col-form-label">{{ $t("customFields.fields.name") }}</label>
                    <div>
                      <input 
                        type="text" 
                        class="form-control" 
                        id="name-input" 
                        v-model="item.name"
                        :class="{ 'is-invalid': v$.item.name.$error }"
                      />
                      <div class="invalid-feedback">
                      {{ $t("common.pleaseEnter") }} {{ $t("customFields.fields.name") }}
                      </div> 
                    </div>
                  </div>
                </div>
               
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="code-input" class="col-form-label">{{ $t("customFields.fields.code") }}</label>
                    <div>
                      <input 
                        type="text" 
                        class="form-control" 
                        id="code-input" 
                        v-model="item.code"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="bgColor-input" class="col-form-label">
                      {{ $t("customFields.fields.bgColor") }}
                      <input type="checkbox" v-model="item.bgColorEnabled"/>
                    </label>
                    <div>
                      <input 
                        :disabled="!item.bgColorEnabled"
                        type="text" 
                        class="form-control" 
                        id="bgColor-input" 
                        v-model="item.bgColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="fgColor-input" class="col-form-label">
                      {{ $t("customFields.fields.fgColor") }}
                      <input type="checkbox" v-model="item.fgColorEnabled"/>
                    </label>
                    <div>
                      <input 
                        :disabled="!item.fgColorEnabled"
                        type="text" 
                        class="form-control" 
                        id="fgColor-input" 
                        v-model="item.fgColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="cssClass-input" class="col-form-label">
                      {{ $t("customFields.fields.cssClass") }}
                      <input type="checkbox" v-model="item.cssClass"/>
                    </label>
                    <div>
                      <input 
                        type="text" 
                        class="form-control" 
                        id="cssClass-input" 
                        v-model="item.cssClass"
                      />
                    </div>
                  </div>
                </div>
              </div>   
              <div class="row gy-2 gx-3 align-items-center">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="name-input" class="col-form-label">{{ $t("customFields.fields.type") }}</label>
                    <div>
                      <select class="form-control" v-model="item.type">
                        <option value="String">{{ $t("customFieldTypes.String") }}</option>
                        <option value="Text">{{ $t("customFieldTypes.Text") }}</option>
                        <option value="Date">{{ $t("customFieldTypes.Date") }}</option>
                        <option value="Dropdown">{{ $t("customFieldTypes.Dropdown") }}</option>
                        <option value="Number">{{ $t("customFieldTypes.Number") }}</option>
                        <option value="Link">{{ $t("customFieldTypes.Link") }}</option>
                        <option value="Address">{{ $t("customFieldTypes.Address") }}</option>
                        <option value="PhoneNumber">{{ $t("customFieldTypes.PhoneNumber") }}</option>
                        <option value="Directory">{{ $t("customFieldTypes.Directory") }}</option>
                        <option value="DirectoryRelatedField">{{ $t("customFieldTypes.DirectoryRelatedField") }}</option>
                        <option value="Table">{{ $t("customFieldTypes.Table") }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> 
              <div v-if="item.type === 'Address'" class="row mb-3">
                <div class="col-md-auto">
                  <div class="form-check form-switch form-switch-md">
                    <input class="form-check-input" type="checkbox" v-model="item.showOnMap"/>
                    <label class="form-check-label">{{ $t("customFields.fields.showOnMap") }}</label>
                  </div>
                </div>
                <div class="col-md-auto" v-if="item.showOnMap">
                  <!-- <div class="dropdown d-inline-block">
                   <a
                      class=""
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      <img style="width:20px; height:34px" :src="(mapMarkerPathList.find(x => x.value === (item.markerIconPath || null)) || {}).path"/>
                     
                      <i class="mdi mdi-chevron-down"></i>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                      aria-labelledby="page-header-notifications-dropdown-v"
                    >
                      <SimpleBar data-simplebar style="max-height: 200px">
                        <a class="m-2 d-inline-block" href="javascript:void(0)" v-for="m in mapMarkerPathList" :key="m.path" @click="item.markerIconPath = m.value">
                          <img style="width:20px; height:34px" :src="m.path"/>
                        </a>
                      </SimpleBar>
                    </div>
                  </div> -->
                              
                </div>
              </div>
              <div v-if="item.type === 'Address' && item.showOnMap" class="row mb-3">
                <div class="col-md-7">
                  <label for="code-input" class="col-form-label">{{ $t("customFields.fields.mapMarkerColor") }}</label>
                  <div>
                    <div class="input-group">
                      <input type="color" class="form-control" v-model="item.mapMarkerColor" style="height:38px"/>
                      <input type="text" class="form-control" v-model="item.mapMarkerColor"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <label for="code-input" class="col-form-label">{{ $t("customFields.fields.mapMarkerScale") }}</label>
                  <div>
                     <input type="text" class="form-control" v-model.number="item.mapMarkerScale"/>
                  </div>
                </div>
                
              </div>
              <div class="mb-4 " v-if="item.type === 'Table'">
                <div class="row">
                  <div class="col-6 mb-2"><label>{{ $t("customFields.fields.tableColumns") }}</label></div>
                  <div class="col-6 text-end mb-2">
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      @click="tableField = {}"
                    > 
                      <font-awesome-icon icon="fa-solid fa-plus" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("customFields.newItem") }}
                      </div>
                    </button>
                  </div>
                </div>
              
                <div class="bg-light p-3">
                  
                  <div class="table-responsive">
                    <table
                      class="table project-list-table table-nowrap align-middle table-borderless"
                      style=""
                    >
                      <thead>
                        <tr>
                          <th scope="col" style="width: 60px">
                            <i class="mdi mdi-drag-variant"></i>
                          </th>
                          <th scope="col" style="width: 40px"></th>
                          <th scope="col">{{ $t("customFields.fields.name") }}</th>
                          <th scope="col">{{ $t("customFields.fields.code") }}</th>
                          <th scope="col">{{ $t("customFields.fields.type") }}</th>
                          <th scope="col">{{ $t("customFields.fields.required") }}</th>
                          <th scope="col">{{ $t("customFields.fields.unique") }}</th>
                          <th scope="col">{{ $t("customFields.fields.hidden") }}</th>
                          <th scope="col" style="width: 80px"></th>
                        </tr>
                      </thead>
                      <tbody  v-if="item.tableFields.length === 0">
                        <tr>
                          <td colspan="100">
                            <em class="text-muted">
                              {{ $t("customFields.noRecordsFound") }}
                            </em>
                          </td>
                        </tr>
                      </tbody>
                      <draggable
                        tag="tbody"
                        v-model="item.tableFields" 
                        group="tableFields" 
                        @start="drag=true" 
                        @end="drag=false" 
                        handle=".drag-area"
                        item-key="index"
                      >
                        <template #item="{element}">
                          <tr>
                            
                            <td class="drag-area">
                              <a href="javascript:void(0)" >
                                <i class="mdi mdi-drag font-size-18"></i>
                              </a>
                            </td>
                            <td>
                              <ul class="list-inline mb-0">
                                <li class="list-inline-item" >
                                  <a href="javascript:void(0)" 
                                    class="btn btn-light btn-sm"  
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                   @click="tableField = element"
                                  >
                                    <i class="bx bx-pencil me-1"></i> {{ $t("customFields.editItem") }}
                                  </a>
                                </li>
                              </ul>
                            </td>
                            <td>
                              <b>{{element.name}}</b>
                            </td>
                            <td>
                              {{element.code}}
                            </td>
                            <td>
                              {{getTypeName(element.type)}}
                              <b v-if="element.type === 'Directory'">[{{(directories.find(x => x.id === element.directoryId) || {}).name}}]</b>
                            </td>
                            <td>
                              <span v-if="element.required">
                                <i class="fas fa-check"/>
                              </span>
                            </td>
                            <td>
                              <span v-if="element.isUnique">
                                <i class="fas fa-check"/>
                              </span>
                            </td>
                            <td>
                              <span v-if="element.hidden">
                                <i class="fas fa-check"/>
                              </span>
                            </td>
                            <td>
                              <ul class="list-inline mb-0">
                                
                                <li class="list-inline-item" >
                                  
                                  <a
                                    class="btn btn-light btn-sm text-danger" 
                                    href="javascript:void(0);"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Delete"
                                    @click="item.tableFields = [...item.tableFields.filter(x => x !== element)]"
                                   
                                    >
                                    <i class="bx bx-trash-alt me-1"></i>
                                    {{ $t("customFields.deleteItem") }}
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </template>
                      </draggable>
                    </table>
                  </div>
                </div>
              </div>

              <div class="mb-4 " v-if="item.type === 'Table'">
                <div class="">
                  <div class="form-check form-switch form-switch-md mb-3">
                    <input class="form-check-input" type="checkbox" id="isadmin-input" v-model="item.tableInlineEdit"/>
                    <label class="form-check-label" for="isadmin-input">{{ $t("customFields.fields.tableInlineEdit") }}</label>
                  </div>
                </div>
              </div>

              <div class="mb-4" v-if="item.type === 'Text'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.rows") }}</label>
                <div>
                  <input class="form-control" type="number" v-model.number="item.rows"/>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Number'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.precision") }}</label>
                <div>
                  <input class="form-control" type="number" v-model.number="item.precision"/>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Directory'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.directory") }}</label>
                <div>
                  <select class="form-control" v-model="item.directoryId"  
                  :class="{ 'is-invalid': (v$.item.directoryId || {}).$error }">
                    <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                    <option v-for="d in directories" :key="d.id" :value="d.id">
                      {{d.name}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                  {{ $t("common.pleaseEnter") }} {{ $t("customFields.fields.directory") }}
                  </div>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Directory' && item.directoryId">
                <label for="name-input" class="col-form-label">{{ $t("common.displayField") }} [{{getDirName(item.directoryId)}}]</label>
                <div>
                  <select class="form-control" v-model="item.displayDirectoryFieldId">
                    <option :value="null">{{ $t("common.displayFieldIsDisplay") }} </option>
                    <option value="Number">{{$t('directory.fields.number')}}</option>
                    <option v-for="(f) in getDirFields(item.directoryId).filter(x => x.type === 'String' || x.type === 'Text' || x.type === 'Number' || x.type === 'Address'  )" :key="f.id" :value="f.id">{{f.name}}</option>
                  </select>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'DirectoryRelatedField'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.selectDirectoryFieldLabel") }}</label>
                <div>
                  <select class="form-control" v-model="item.directoryFieldId">
                    <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                    <option v-for="(f) in fields.filter(x => x.type === 'Directory')" :key="f.id" :value="f.id">{{f.name}}</option>
                  </select>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'DirectoryRelatedField' && item.directoryFieldId">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.selectDirectoryRelatedFieldLabel") }}</label>
                <div>
                  <select class="form-control" v-model="item.directoryRelatedFieldId">
                    <option :value="null">---{{ $t("common.nothingSelected") }}---</option>
                    <option v-for="f in getRelatedFields()" :key="f.id" :value="f.id">{{f.name}}</option>
                  </select>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Directory'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.directoryLookupControl") }}</label>
                <div>
                  <select class="form-control" v-model="item.directoryLookupControl">
                    <option value="Dropdown">
                    {{ $t("customFields.fields.lookupControlDropdown") }}
                    </option>
                    <option value="Typeahead">
                    {{ $t("customFields.fields.lookupControlTypeahead") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Directory' && getDirectoryName(item.directoryId)">
                <div class="form-check form-switch form-switch-md mb-3">
                  <input class="form-check-input" type="checkbox" v-model="item.showOnDirectoryForm"/>
                  <label class="form-check-label">{{ $t("customFields.fields.showOnForm") }} [{{getDirectoryName(item.directoryId)}}]</label>
                </div>
              </div>

              <div class="mb-4" v-if="item.type === 'Directory' && item.showOnDirectoryForm && getDirectoryName(item.directoryId)">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.nameOnForm") }}  [{{getDirectoryName(item.directoryId)}}]</label>
                <div>
                  <input type="text" class="form-control" v-model="item.nameOnDirectoryForm"/>
                </div>
              </div>
              <div class="mb-4" v-if="item.type === 'Dropdown'">
                <label for="name-input" class="col-form-label">{{ $t("customFields.fields.dropdownItems") }}</label> 
                <div>
                  <table class="table table-sm table-borderless mb-0">
                    <thead>
                      <tr>
                        <th style="max-width:46px; width:46px">
                          <a href="javascript:void(0)" @click="addDropdownItem" class="btn-light btn ">
                            <eva-icon class="icon nav-icon text-primary" name="plus-outline"></eva-icon>
                          </a>
                        </th>
                      
                        <th  class="col ">
                          <input class="form-control" type="text" v-model="dd.text" placeholder="Text" v-on:keyup.enter="addDropdownItem"/>
                        </th>
                        <th style="max-width:30px; width:30px">
                          
                        </th>
                      </tr>
                    </thead>
                    <draggable
                      tag="tbody"
                      v-model="item.dropdownItems" 
                      group="dditems"
                      @start="drag=true" 
                      @end="drag=false" 
                      item-key="index"  
                      handle=".draggable-handle"
                    >
                      <template #item="{element}">
                        <tr>
                          <td style="max-width:50px; width:50px"  class="text-center draggable-handle">
                            <a href="javascript:void(0)" >
                              <i class="mdi mdi-drag font-size-22 mt-1"></i>
                            </a>
                           
                          </td>
                        
                          <td>
                            <input class="form-control" type="text" v-model="element.text" placeholder="Text"/>
                            <small class="text-muted" v-if="element.value">ID:{{element.value}}</small>
                          </td> 
                          <td>
                            <a href="javascript:void(0)" class="btn-light btn" @click="deleteDropdownItem(element)" >
                              <i class="bx bx-trash-alt  text-danger"></i>
                            </a>
                          </td> 
                        </tr>
                      </template>  
                    </draggable>
                  </table>
                  
                </div>
              </div>
              <div class="row" >
                <div class="col-auto" v-if="item.type !== 'DirectoryRelatedField' && item.type !== 'Table'">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-required" v-model="item.required"/>
                        <label class="form-check-label" for="isadmin-required">{{ $t("customFields.fields.required") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto"  v-if="item.type !== 'DirectoryRelatedField' && item.type !== 'Table'">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-isUnique" v-model="item.isUnique"/>
                        <label class="form-check-label" for="isadmin-isUnique">{{ $t("customFields.fields.unique") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto"  v-if="item.type === 'String'">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-suggestionEnabled" v-model="item.suggestionEnabled"/>
                        <label class="form-check-label" for="isadmin-suggestionEnabled">{{ $t("customFields.fields.suggestionEnabled") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto"  v-if="item.type !== 'DirectoryRelatedField' ">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-hidden" v-model="item.hidden"/>
                        <label class="form-check-label" for="isadmin-hidden">{{ $t("customFields.fields.hidden") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto" v-if="!item.hidden && item.type !== 'Table'">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-input" v-model="item.visibleOnGrid"/>
                        <label class="form-check-label" for="isadmin-input">{{ $t("customFields.fields.visibleOnGrid") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto" v-if="directoryType === 'Process'">
                  <div class=" mb-3">
                    <div class="">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input class="form-check-input" type="checkbox" id="isadmin-input" v-model="item.visibleOnBoard"/>
                        <label class="form-check-label" for="isadmin-input">{{ $t("customFields.fields.visibleOnBoard") }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-auto">
                  <div class="form-check form-switch form-switch-md mb-3">
                    <input class="form-check-input" type="checkbox" id="inlineLabel" v-model="item.inlineLabel"/>
                    <label class="form-check-label" for="inlineLabel">{{$t('directory.fields.inlineLabel')}}</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="name-input" class="col-form-label">Description</label>
                <div>
                <textarea class="form-control" v-model="item.description">

                </textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer row">
           
              <div class="col">
                <button
                  type="button"
                  class="btn btn-light"
                  @click="close()"
                >
                  {{ $t("common.closeButtonTitle") }}
                </button>
              </div>
            
              <div class="col text-end">
                <button type="button" class="btn btn-primary"
                  v-if="!deletedAt"
                  :disabled="processing" 
                  @click="submit"
                >
                <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
                <i class="fa fa-save me-2" v-if="!processing"/>
                  {{ $t("common.saveButtonTitle") }}
                </button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
  
</template>
