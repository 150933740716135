<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import { customFieldTypeName } from '@/helpers'
import EditForm from './edit-form'
import Swal from 'sweetalert2'

export default {
  components: {
    Layout,
    draggable,
    EditForm
  },
  props: {
    entity: {
      type: String
    }
  },
  data() {
    return {
     
      editItem: null
    };
  },
  created() {
    
  },
  watch: {
    pagetitle(newValue, oldValue) {
      if(newValue !== oldValue) {
        document.title = this.pagetitle;
      }
    }
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('processTypes', {
      processTypes: 'processTypes'
    }),
    ...mapGetters('customFields', {
      loading: 'loading',
      deleting: 'deleting',
      customFields: 'items'
    }),
    entityId () {
      return this.entity.toLowerCase().trim() === 'contact' ? 'Contact' : this.entity;
    },  
    directory () {
      return this.directories.find(x => x.id === this.entityId);
    },
    processType () {
      return this.processTypes.find(x => x.id === this.entityId);
    },
    pagetitle () {
      if(this.directory) {
        return  this.directory.name + ' [' + this.$t('customFields.pageTitle') + ']';
      } else if(this.entityId === 'Contact') {
        return this.$t('common.contactEntity') + ' [' + this.$t('customFields.pageTitle') + ']';
      } else if(this.processType) {
         return  this.processType.name + ' [' + this.$t('customFields.pageTitle') + ']';
      }
      return 'Loading...';
    },
    isBusy() {
      return this.loading;
    },
    items:
    {
      get: function() {
        return (this.customFields || {})[this.entityId] || [];
      },
      set: function(newValue) {
        const list = newValue.map(x => x.id);
        this.reorder({ entity: this.entityId, list});
      }
    }
  },
  methods: {
    ...mapActions('customFields', {
      delete: 'delete',
      reorder: 'reorder',
      restore: 'restore'
    }),
    onClose() {
      this.editItem = null;
    },
    getTypeName(type){
      return this.$t('customFieldTypes.' + customFieldTypeName[type]);
    },
    onEditClose(){
      this.editItem = null;
    },
    tryToDelete(id) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.delete({entity: this.entityId, id}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
          });
        }
      });
    },
    tryToRestore(id) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
         confirmButtonText: this.$t('common.yesRestoreIt')
      }).then(result => {
        if (result.value) {
          this.restore({entity: this.entityId, id}).then(()=>{
            Swal.fire(this.$t('common.restoredTitle'), this.$t('common.restoredText'), "success");
          });
        }
      });
    },
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
    <edit-form v-if="editItem" @close="onEditClose" :field="editItem" :entity="entityId">

    </edit-form>

    <div class="row align-items-center">
     
     <div class="col">
       <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
        <div class="me-2">
           <button
              type="button"
              class="btn btn-primary"
               @click="editItem = { definition: {}}"
              > 
              <font-awesome-icon icon="fa-solid fa-plus" />
              <div class="text-sm-end d-none d-md-inline-block ms-2">
                {{ $t("customFields.newItem") }}
              </div>
            </button>
          </div>
       </div>
     </div>
   </div>
     <div class="row mt-2">
        <div class="table-responsive">
          <table
            class="table project-list-table table-nowrap align-middle table-borderless"
            style="margin-bottom:100px"
          >
            <thead>
              <tr>
                <th scope="col" style="width: 60px">
                  <i class="mdi mdi-drag-variant"></i>
                </th>
                <th scope="col" style="width: 40px"></th>
                <th scope="col">{{ $t("customFields.fields.code") }}</th>
                <th scope="col">{{ $t("customFields.fields.name") }}</th>
                <th scope="col">{{ $t("customFields.fields.type") }}</th>
                <th scope="col">{{ $t("customFields.fields.required") }}</th>
                <th scope="col">{{ $t("customFields.fields.unique") }}</th>
                <th scope="col">{{ $t("customFields.fields.hidden") }}</th>
                <th scope="col">{{ $t("customFields.fields.visibleOnGrid") }}</th>
                <th scope="col">{{ $t("customFields.fields.description") }}</th>
                <th scope="col" style="width: 80px"></th>
              </tr>
            </thead>
            <tbody  v-if="items.length === 0">
              <tr>
                <td colspan="100">
                  <em class="text-muted">
                    {{ $t("customFields.noRecordsFound") }}
                  </em>
                </td>
              </tr>
            </tbody>
            <draggable
              tag="tbody"
              v-model="items" 
              group="fields" 
              @start="drag=true" 
              @end="drag=false" 
              handle=".drag-area"
              item-key="index"
            >
              <template #item="{element}">
                <tr>
                  
                  <td class="drag-area">
                    <a href="javascript:void(0)" >
                      <i class="mdi mdi-drag font-size-18"></i>
                    </a>
                  </td>
                  <td>
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item" >
                        <a href="javascript:void(0)" 
                          class="btn btn-light btn-sm"  
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          @click="editItem = element"
                        >
                          <i class="bx bx-pencil me-1"></i>{{ $t("customFields.editItem") }}
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td>
                    {{element.definition.code}}
                  </td>
                  <td>
                    {{element.definition.name}}
                    <span class="badge bg-danger ms-2" v-if="element.deletedAt"> {{ $t("customFields.fields.deleted") }}</span>
                  </td>
                  <td>
                    {{getTypeName(element.definition.type)}}
                     <b v-if="element.definition.type === 'Directory'">[{{(directories.find(x => x.id === element.definition.directoryId) || {}).name}}]</b>
                  </td>
                  <td>
                    <span v-if="element.definition.required">
                      <i class="fas fa-check"/>
                    </span>
                  </td>
                  <td>
                    <span v-if="element.definition.isUnique">
                      <i class="fas fa-check"/>
                    </span>
                  </td>
                  <td>
                    <span v-if="element.definition.hidden">
                      <i class="fas fa-check"/>
                    </span>
                  </td>
                  <td>
                    <span v-if="element.definition.visibleOnGrid">
                      <i class="fas fa-check"/>
                    </span>
                  </td>
                  <td>
                      {{element.definition.description}}
                  </td>
                  <td>
                    <ul class="list-inline mb-0">
                      
                      <li class="list-inline-item" >
                        <a
                          v-if="element.deletedAt"
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          
                          @click="tryToRestore(element.id)"
                          class="btn btn-light btn-sm text-warning"
                          >
                          <i class="fas fa-recycle  me-1" v-if="deleting !== element.id"></i>
                          <i class="fa fa-spin fa-spinner  me-1" v-if="deleting === element.id"></i>
                          {{ $t("customFields.restoreItem") }}
                        </a>
                        <a
                          v-if="!element.deletedAt"
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                          @click="tryToDelete(element.id)"
                          class="btn btn-light btn-sm text-danger" 
                         
                          >
                          <i class="bx bx-trash-alt me-1" v-if="deleting !== element.id"></i>
                          <i class="fa fa-spin fa-spinner me-1" v-if="deleting === element.id"></i>
                          {{ $t("customFields.deleteItem") }}
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
     </div>
  </Layout>
</template>
